import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  pdf,
  Image,
  Link,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import Button from "../../Components/_NEW/Button";
import {
  positions,
  countries,
  cuisines,
  languages,
  months,
  sectors,
  levels,
} from "../../Lists/lists";
import { findOption } from "../../Lib/findOption";
import { translate } from "../../Translations/translate";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 12,
    backgroundColor: "#4F5256",
    paddingBottom: 30,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  half: {
    width: "50%",
  },
  header: {
    backgroundColor: "#000000",
    padding: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#FFFFFF",
    fontSize: 10,
  },
  logo: {
    width: "20%",
  },
  hero: {
    padding: 10,
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: "#26292e",
    color: "#FFFFFF",
    marginBottom: 10,
  },
  section: {
    padding: 20,
    backgroundColor: "#FFFFFF",
    color: "#26292e",
    margin: 10,
    borderRadius: 8,
  },
  footer: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 10,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    color: "#FFFFFF",
    opacity: 0.8,
  },
});

const heroStyles = StyleSheet.create({
  image: {
    width: 200,
    height: 200,
    marginRight: 20,
    borderStyle: "solid",
    borderColor: "#FFFFFF",
    borderWidth: 3,
  },
  name: {
    fontSize: 24,
    marginBottom: 10,
  },
  title: {
    textTransform: "uppercase",
    fontSize: 10,
    letterSpacing: 1.5,
    marginBottom: 10,
    marginTop: 20,
  },
  text: {
    opacity: 0.8,
  },
  button: {
    borderStyle: "solid",
    borderColor: "#FFFFFF",
    borderWidth: 1,
    color: "#FFFFFF",
    padding: 10,
    borderRadius: 10,
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonLabel: {
    textDecoration: "none",
    textTransform: "uppercase",
    fontSize: 10,
    letterSpacing: 1.5,
  },
  capsuleContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  capsule: {
    backgroundColor: "#4F5256",
    padding: 8,
    borderRadius: 10,
    flexGrow: 0,
    marginRight: 5,
    marginBottom: 5,
  },
});

const sectionStyles = StyleSheet.create({
  title: {
    textTransform: "uppercase",
    fontSize: 16,
    letterSpacing: 1.5,
  },
  subtitle: {
    textTransform: "uppercase",
    fontSize: 10,
    letterSpacing: 1.5,
  },
  item: {
    marginBottom: 10,
    marginTop: 20,
  },
  description: {
    marginTop: 15,
  },
  starsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  star: {
    height: 10,
    marginRight: 2,
  },
  url: {
    color: "#929eb1",
  },
  language: {
    width: "30%",
    marginBottom: 30,
    marginRight: 15,
  },
});

const stripHtmlTags = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  const decodedText = txt.value; // Decode HTML entities
  const strippedText = decodedText.replace(/<[^>]*>/g, ""); // Remove HTML tags
  const trimmedText = strippedText.trim(); // Trim whitespace

  // Replace common HTML entities (like &nbsp;)
  const entityFreeText = trimmedText.replace(/&[a-zA-Z0-9#]+;/g, " ");

  // Sanitize and remove non-printable characters (including zero-width spaces)
  const sanitizedText = entityFreeText
    .replace(/[\u200B-\u200D\uFEFF]/g, "") // Remove zero-width spaces and BOM
    .replace(/[^\x20-\x7E\xC0-\u017F]+/g, ""); // Keep accented Latin-1 characters

  const normalizedText = sanitizedText.normalize("NFC"); // Normalize to canonical form

  return normalizedText.length > 0 ? normalizedText : undefined; // Return the final text
};

const ExperienceItem = ({ lang, item }) => {
  const rating = parseInt(item.value.rating) || undefined;
  const starType = item.value.ratingType || item.value.ratingtype || undefined;
  const origin = window.location.origin;
  const description = stripHtmlTags(item.value.description);

  return (
    <View style={sectionStyles.item} wrap={false}>
      <View style={styles.row}>
        <View style={{ ...styles.half, marginRight: 10 }}>
          <Text style={{ ...sectionStyles.subtitle, marginBottom: 10 }}>
            {findOption(item.value.position, positions[lang])}
          </Text>
          <Text>
            {`${item.value.fromYear} ${findOption(
              item.value.fromMonth,
              months[lang]
            ).substring(0, 3)} - ${
              item.value.currently
                ? translate(lang, "currently_work_here")
                : `${item.value.toYear} ${findOption(
                    item.value.toMonth,
                    months[lang]
                  ).substring(0, 3)}`
            }`}
          </Text>
          {item.value.location.formatted_address && (
            <Text>{item.value.location.formatted_address}</Text>
          )}
        </View>
        <View style={styles.half}>
          <View style={{ ...sectionStyles.starsRow, marginBottom: 10 }}>
            <Link
              style={{ ...sectionStyles.subtitle, marginRight: 10 }}
              src={
                item.value.url &&
                typeof item.value.url === "string" &&
                item.value.url.length > 0
                  ? item.value.url
                  : ""
              }
            >
              {item.value.company}
            </Link>

            {rating && starType && (
              <View style={sectionStyles.starsRow}>
                {Array.from(Array(rating).keys()).map((i) => (
                  <Image
                    key={i}
                    src={`${origin}/static/images/star_${starType}.png`}
                    style={sectionStyles.star}
                  />
                ))}
              </View>
            )}
          </View>
          <Text>{findOption(item.value.cuisine, cuisines[lang])}</Text>
          <Text>{findOption(item.value.sector, sectors[lang])}</Text>
        </View>
      </View>
      {description &&
        typeof description === "string" &&
        description.length > 0 && (
          <Text style={sectionStyles.description}>{description}</Text>
        )}
    </View>
  );
};

const EducationItem = ({ lang, item }) => {
  const description = stripHtmlTags(item.value.description);

  return (
    <View style={sectionStyles.item} wrap={false}>
      <View style={styles.row}>
        <View style={{ ...styles.half, marginRight: 10 }}>
          <Text style={{ ...sectionStyles.subtitle, marginBottom: 10 }}>
            {item.value.title}
          </Text>
          <Text>
            {`${item.value.fromYear} ${findOption(
              item.value.fromMonth,
              months[lang]
            ).substring(0, 3)} - ${
              item.value.currently
                ? translate(lang, "currently_studying")
                : `${item.value.toYear} ${findOption(
                    item.value.toMonth,
                    months[lang]
                  ).substring(0, 3)}`
            }`}
          </Text>
        </View>
        <View style={styles.half}>
          <Text style={{ ...sectionStyles.subtitle, marginBottom: 10 }}>
            {item.value.center}
          </Text>
          <Link
            style={sectionStyles.url}
            src={
              item.value.url &&
              typeof item.value.url === "string" &&
              item.value.url.length > 0
                ? item.value.url
                : ""
            }
          >
            {item.value.url}
          </Link>
        </View>
      </View>
      {description &&
        typeof description === "string" &&
        description.length > 0 && (
          <Text style={sectionStyles.description}>{description}</Text>
        )}
    </View>
  );
};

const LanguageItem = ({ lang, item }) => (
  <View style={sectionStyles.language} wrap={false}>
    <Text style={{ ...sectionStyles.subtitle, marginBottom: 10 }}>
      {findOption(item.language, languages[lang])}
    </Text>
    <Text> {findOption(item.level, levels[lang])}</Text>
  </View>
);

const PDFDocument = ({ lang, profile }) => {
  const origin = window.location.origin;
  const image =
    typeof profile.images.profile === "object" &&
    profile.images.profile.sizes.square.url
      ? profile.images.profile.sizes.square.url
      : `${origin}/static/images/empty_600_600.png`;

  return (
    <Document>
      <Page size={"A4"} style={styles.page}>
        <View
          fixed
          render={({ pageNumber }) => {
            if (pageNumber !== 1) {
              return <View style={{ height: 10 }} />;
            }
          }}
        />
        <View style={styles.header}>
          <Image
            src={`${origin}/static/images/chefslink_logo.png`}
            style={styles.logo}
          />
          <View style={{ ...styles.row, opacity: 0.8 }}>
            <Text>{translate(lang, "cv_provided_by")}</Text>
            <Link src={`www.chefslink.com/${lang}`} style={{ marginLeft: 3 }}>
              www.chefslink.com
            </Link>
          </View>
        </View>
        <View style={styles.hero}>
          <View style={styles.row}>
            <Image src={image} style={heroStyles.image} />
            <View>
              {profile.name && (
                <Text style={heroStyles.name}>{profile.name}</Text>
              )}
              {profile.position && (
                <Text style={{ ...heroStyles.text, marginBottom: 5 }}>
                  {findOption(profile.position, positions[lang])}
                </Text>
              )}
              {profile.location.formatted_address && (
                <Text style={heroStyles.text}>
                  {profile.location.formatted_address}
                </Text>
              )}
              <View style={heroStyles.button}>
                <Link
                  src={`${origin}/${lang}/chef/view/${profile.id}/${profile.slug}`}
                  style={heroStyles.buttonLabel}
                >
                  {translate(lang, "contact_the_chef")}
                </Link>
              </View>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.half}>
              {profile.visas.length > 0 && (
                <View>
                  <Text style={heroStyles.title}>
                    {translate(lang, "work_visas")}
                  </Text>
                  <View style={heroStyles.capsuleContainer}>
                    {profile.visas.map((v) => (
                      <View key={v} style={heroStyles.capsule}>
                        <Text style={heroStyles.text}>
                          {findOption(v, countries[lang])}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              )}
              {profile.nationalities.length > 0 && (
                <View>
                  <Text style={heroStyles.title}>
                    {translate(lang, "nationalities")}
                  </Text>
                  <View style={heroStyles.capsuleContainer}>
                    {profile.nationalities.map((n) => (
                      <View key={n} style={heroStyles.capsule}>
                        <Text style={heroStyles.text}>
                          {findOption(n, countries[lang])}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              )}
            </View>
            <View style={styles.half}>
              {profile.cuisine.length > 0 && (
                <View>
                  <Text style={heroStyles.title}>
                    {translate(lang, "cuisine")}
                  </Text>
                  <View style={heroStyles.capsuleContainer}>
                    {profile.cuisine.map((c) => (
                      <View key={c} style={heroStyles.capsule}>
                        <Text style={heroStyles.text}>
                          {findOption(c, cuisines[lang])}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
        {profile.description &&
          typeof profile.description === "string" &&
          profile.description.length > 0 && (
            <View>
              <View style={styles.section}>
                <Text style={{ ...sectionStyles.title, marginBottom: 20 }}>
                  {translate(lang, "about_chef")}
                </Text>
                <Text>{stripHtmlTags(profile.description)}</Text>
              </View>
            </View>
          )}
        {profile.experience.length > 0 && (
          <View
            wrap={false}
            break={
              profile.description &&
              typeof profile.description === "string" &&
              profile.description.length < 1695
                ? true
                : false
            }
          >
            <View style={styles.section} wrap={false}>
              <Text style={{ ...sectionStyles.title, marginBottom: 5 }}>
                {translate(lang, "work_experience")}
              </Text>
              {profile.experience.map((e) => (
                <ExperienceItem key={e.id} lang={lang} item={e} />
              ))}
            </View>
          </View>
        )}
        {profile.education.length > 0 && (
          <View wrap={false}>
            <View style={styles.section} wrap={false}>
              <Text style={{ ...sectionStyles.title, marginBottom: 5 }}>
                {translate(lang, "education")}
              </Text>
              {profile.education.map((e) => (
                <EducationItem key={e.id} lang={lang} item={e} />
              ))}
            </View>
          </View>
        )}
        {profile.languages.length > 0 && (
          <View wrap={false}>
            <View style={styles.section} wrap={false}>
              <Text style={{ ...sectionStyles.title, marginBottom: 20 }}>
                {translate(lang, "languages")}
              </Text>
              <View style={{ ...styles.row, flexWrap: "wrap" }}>
                {profile.languages.map((l) => (
                  <LanguageItem key={l.id} lang={lang} item={l} />
                ))}
              </View>
            </View>
          </View>
        )}
        {profile.referrals.length > 0 && (
          <View wrap={false}>
            <View style={styles.section} wrap={false}>
              <Text style={{ ...sectionStyles.title, marginBottom: 5 }}>
                {translate(lang, "more_info")}
              </Text>
              {profile.referrals.map((r) => {
                if (
                  r.value.description &&
                  typeof r.value.description === "string" &&
                  r.value.description.length > 0
                ) {
                  return (
                    <Text key={r.id} style={sectionStyles.item} wrap={false}>
                      {stripHtmlTags(r.value.description)}
                    </Text>
                  );
                }
              })}
            </View>
          </View>
        )}
        <View fixed style={styles.footer}>
          <Text
            fixed
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
          <Text>{translate(lang, "copyright")}</Text>
        </View>
      </Page>
    </Document>
  );
};

const generatePDFDocument = async ({ profile, lang }) => {
  try {
    const blob = await pdf(
      <PDFDocument profile={profile} lang={lang} />
    ).toBlob();
    saveAs(blob, `chefslink-${profile.slug}-${lang}.pdf`);
  } catch (error) {
    console.log("Error generating PDF", error);
  }
};

const ChefViewPDF = ({ profile, lang }) => {
  return (
    <Button
      onClick={() => generatePDFDocument({ profile, lang })}
      className="outline"
      label={translate(lang, "download_pdf")}
    />
  );
};

export default ChefViewPDF;
