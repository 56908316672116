import React, { Component } from "react";
import Btn from "../../Components/Btn/Btn";
import Overlay from "./Overlay";
import Select from "react-select";
import { connect } from "react-redux";
import { ActionCreators } from "../../Actions";
import { days, hours } from "../../Lists/lists";
import { restaurantEditOpeningHours } from "../../Services/Restaurant/restaurant";
import { valueByKey } from "../../Lib/valueByKey";
import { translate } from "../../Translations/translate";
import { withRouter } from "react-router";
import TimePicker from "react-time-picker";

class RestaurantOpeningHoursOverlay extends Component {
  constructor(props) {
    super(props);
    let value = this.props.data.value;
    let state = {
      id: this.props.data.id,
      errors: [],
    };
    let fields = [];
    const { lang } = this.props.match.params;
    days[lang].forEach((d) => {
      let day = d.value;
      let dayLunchFrom = day + "lunchFrom";
      let dayLunchTo = day + "lunchTo";
      let dayDinnerFrom = day + "dinnerFrom";
      let dayDinnerTo = day + "dinnerTo";
      state[dayLunchFrom] = valueByKey(value, dayLunchFrom, null);
      state[dayLunchTo] = valueByKey(value, dayLunchTo, null);
      state[dayDinnerFrom] = valueByKey(value, dayDinnerFrom, null);
      state[dayDinnerTo] = valueByKey(value, dayDinnerTo, null);
      fields.push({ field: dayLunchFrom });
      fields.push({ field: dayLunchTo });
      fields.push({ field: dayDinnerFrom });
      fields.push({ field: dayDinnerTo });
    });
    state.fields = fields;
    this.state = state;
  }
  render() {
    const { lang } = this.props.match.params;
    return (
      <Overlay
        title={translate(lang, "edit_opening_hours")}
        className="edit-opening-hours"
      >
        <div className="col-group form">
          {this.renderDay()}
          <div className="col-mb-12 actions multiple">
            <div className="main">
              <Btn
                text={translate(lang, "cancel").toUpperCase()}
                className="inverted"
                callback={() => {
                  this.props.showOverlay(null);
                }}
              />
              <Btn
                text={translate(lang, "save").toUpperCase()}
                loading={this.state.loading}
                callback={() => {
                  this.save();
                }}
              />
            </div>
          </div>
        </div>
      </Overlay>
    );
  }
  renderDay() {
    const { lang } = this.props.match.params;
    let items = [];
    days[lang].forEach((day) => {
      items.push(
        <div key={"day" + day.value} className="col-mb-12 day">
          <h3>{day.label}</h3>
          {this.renderHours(day.value)}
        </div>
      );
    });
    return items;
  }
  renderHours(day) {
    const { lang } = this.props.match.params;
    const mobile = this.props.deviceScreen === "mobile";
    let dayLunchFrom = day + "lunchFrom";
    let dayLunchTo = day + "lunchTo";
    let dayDinnerFrom = day + "dinnerFrom";
    let dayDinnerTo = day + "dinnerTo";
    return (
      <div key={day} className="col-group">
        <div className="col-mb-6 col-3">
          <label htmlFor="type">{translate(lang, "lunch")}</label>
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <label
              style={{
                marginRight: 5,
                paddingBottom: 5,
                paddingTop: 5,
                fontSize: 14,
                textTransform: "lowercase",
                color: "#929eb1",
                minWidth: 28.5,
              }}
            >
              {translate(lang, "from")}
            </label>
            <TimePicker
              name="lunchFrom"
              onChange={(value) => {
                this.setState({ [dayLunchFrom]: value ? value : null });
              }}
              value={
                typeof this.state[dayLunchFrom] === "number"
                  ? `${this.state[dayLunchFrom]}:00`
                  : this.state[dayLunchFrom]
              }
              clockIcon={null}
              disableClock={true}
              className={`${
                this.state.errors.indexOf(dayLunchTo) !== -1 ? "error" : ""
              }`}
              format="HH:mm"
              hourPlaceholder={
                typeof this.state[dayLunchFrom] === "number"
                  ? `${this.state[dayLunchFrom]}`
                  : `${
                      this.state[dayLunchFrom] !== null
                        ? this.state[dayLunchFrom].split(":")[0]
                        : "--"
                    }`
              }
              minutePlaceholder={
                typeof this.state[dayLunchFrom] === "number"
                  ? "00"
                  : `${
                      this.state[dayLunchFrom] !== null
                        ? this.state[dayLunchFrom].split(":")[1]
                        : "--"
                    }`
              }
            />
          </div>
        </div>
        <div className="col-mb-6 col-3">
          <label htmlFor="type">&nbsp;</label>
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <label
              style={{
                marginRight: 5,
                paddingBottom: 5,
                paddingTop: 5,
                fontSize: 14,
                textTransform: "lowercase",
                color: "#929eb1",
                minWidth: 28.5,
              }}
            >
              {translate(lang, "to")}
            </label>
            <TimePicker
              name="lunchTo"
              onChange={(value) => {
                this.setState({ [dayLunchTo]: value ? value : null });
              }}
              value={
                typeof this.state[dayLunchTo] === "number"
                  ? `${this.state[dayLunchTo]}:00`
                  : this.state[dayLunchTo]
              }
              clockIcon={null}
              disableClock={true}
              className={`${
                this.state.errors.indexOf(dayLunchTo) !== -1 ? "error" : ""
              }`}
              format="HH:mm"
              hourPlaceholder={
                typeof this.state[dayLunchTo] === "number"
                  ? `${this.state[dayLunchTo]}`
                  : `${
                      this.state[dayLunchTo] !== null
                        ? this.state[dayLunchTo].split(":")[0]
                        : "--"
                    }`
              }
              minutePlaceholder={
                typeof this.state[dayLunchTo] === "number"
                  ? "00"
                  : `${
                      this.state[dayLunchTo] !== null
                        ? this.state[dayLunchTo].split(":")[1]
                        : "--"
                    }`
              }
            />
          </div>
        </div>
        <div className="col-mb-6 col-3">
          <label htmlFor="type">{translate(lang, "dinner")}</label>
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <label
              style={{
                marginRight: 5,
                paddingBottom: 5,
                paddingTop: 5,
                fontSize: 14,
                textTransform: "lowercase",
                color: "#929eb1",
                minWidth: 28.5,
              }}
            >
              {translate(lang, "from")}
            </label>
            <TimePicker
              name="dinnerFrom"
              onChange={(value) => {
                this.setState({ [dayDinnerFrom]: value ? value : null });
              }}
              value={
                typeof this.state[dayDinnerFrom] === "number"
                  ? `${this.state[dayDinnerFrom]}:00`
                  : this.state[dayDinnerFrom]
              }
              clockIcon={null}
              disableClock={true}
              format="HH:mm"
              hourPlaceholder={
                typeof this.state[dayDinnerFrom] === "number"
                  ? `${this.state[dayDinnerFrom]}`
                  : `${
                      this.state[dayDinnerFrom] !== null
                        ? this.state[dayDinnerFrom].split(":")[0]
                        : "--"
                    }`
              }
              minutePlaceholder={
                typeof this.state[dayDinnerFrom] === "number"
                  ? "00"
                  : `${
                      this.state[dayDinnerFrom] !== null
                        ? this.state[dayDinnerFrom].split(":")[1]
                        : "--"
                    }`
              }
              className={`${
                this.state.errors.indexOf(dayDinnerFrom) !== -1 ? "error" : ""
              }`}
            />
          </div>
        </div>
        <div className="col-mb-6 col-3">
          <label htmlFor="type">&nbsp;</label>
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <label
              style={{
                marginRight: 5,
                paddingBottom: 5,
                paddingTop: 5,
                fontSize: 14,
                textTransform: "lowercase",
                color: "#929eb1",
                minWidth: 28.5,
              }}
            >
              {translate(lang, "to")}
            </label>
            <TimePicker
              name="dinnerTo"
              onChange={(value) => {
                this.setState({ [dayDinnerTo]: value ? value : null });
              }}
              value={
                typeof this.state[dayDinnerTo] === "number"
                  ? `${this.state[dayDinnerTo]}:00`
                  : this.state[dayDinnerTo]
              }
              clockIcon={null}
              disableClock={true}
              className={`${
                this.state.errors.indexOf(dayDinnerTo) !== -1 ? "error" : ""
              }`}
              format="HH:mm"
              hourPlaceholder={
                typeof this.state[dayDinnerTo] === "number"
                  ? `${this.state[dayDinnerTo]}`
                  : `${
                      this.state[dayDinnerTo] !== null
                        ? this.state[dayDinnerTo].split(":")[0]
                        : "--"
                    }`
              }
              minutePlaceholder={
                typeof this.state[dayDinnerTo] === "number"
                  ? "00"
                  : `${
                      this.state[dayDinnerTo] !== null
                        ? this.state[dayDinnerTo].split(":")[1]
                        : "--"
                    }`
              }
            />
          </div>
        </div>
      </div>
    );
  }
  save() {
    const { lang } = this.props.match.params;
    // let errors = validate(this.state.fields, this.state)
    // this.setState({ errors: errors })
    let errors = [];
    if (errors.length === 0) {
      this.setState({ loading: true });

      let currentState = Object.assign({}, this.state);
      delete currentState["errors"];
      delete currentState["id"];

      Object.keys(currentState).forEach((key) => {
        if (currentState[key] === -1) {
          currentState[key] = "";
        }
      });

      //SAVE
      let data = {
        rid: this.props.data.rid,
        id: this.state.id,
        name: "opening-hours",
        order: 0,
        value: JSON.stringify(currentState),
        lang: lang,
      };
      restaurantEditOpeningHours(data)
        .then((res) => {
          this.setState({ loading: false });
          if (!res.data.success) {
            this.props.showOverlay("error", { message: res.data.message });
            return;
          }
          let data = res.data.data;
          this.props.setUser(data.user);
          this.props.showOverlay(null);
        })
        .catch((e) => {
          this.props.showOverlay("error", { message: e.message });
        });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    deviceScreen: state.deviceScreen,
  };
};
export default withRouter(
  connect(mapStateToProps, ActionCreators)(RestaurantOpeningHoursOverlay)
);
