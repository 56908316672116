export const en = {
  about: "About",
  about_chef: "About the Chef",
  about_restaurant: "About the Restaurant",
  about_us: "About Us",
  accept: "Accept",
  accepted: "Accepted",
  action_completed: "Action completed",
  active: "Active",
  active_NUM: "Active (%@)",
  active_offers: "Active offers",
  active_offers_NUM: "Active Offers: %@",
  ad_manager: "Ad manager",
  add: "Add",
  add_address: "Add address",
  add_email: "Add email",
  add_media: "Add media",
  add_phone_number: "Add phone number",
  add_restaurant: "Add restaurant",
  add_social_network: "Add social network",
  add_to_job_description: "Add to job description",
  address: "Address",
  ads: "Ads",
  all_the_tools_you_need: "All the tools you will need",
  already_member: "Already a member?",
  and: " and ",
  applic: "Applic.",
  applicants: "Applicants",
  applicants_NUM: "(%@) Applicants",
  application_confirmation: "Application confirmation",
  application_form: "Application form",
  application_sent: "Application sent",
  applications: "Applications",
  applications_NUM: "Applications (%@)",
  applied_on: "Applied on",
  apply: "Apply",
  apply_jobs_content:
    "The Job Offers posted on our website are a summary of only the most important and necessary information. By simply applying to the Job Offer, the restaurant will be notified that you are interested in that Job and receive a full description of your Profile.",
  apply_jobs_subtitle: "Search for Job offers in our network",
  apply_jobs_title: "Apply for Jobs",
  attach_file: "Attach file",

  back: "Back",
  back_to_active_chefs: "Go back to active chefs",
  back_to_all_offers: "Back to all the offers",
  back_to_lastest_offers: "Go back to latest offers",
  back_to_restaurants_list: "Go back to restaurants list",
  block: "Block",
  block_page: "Block Page",
  block_this_page: "Block this page from seeing your profile",
  blog: "Blog",
  book_stage: "Book a stage with us!",
  browse: "browse",

  calendar: "Calendar",
  callback: "Callback",
  cancel: "Cancel",
  center: "Center",
  chef_profile: "Chef Profile",
  chef_requirements: "Chef requirements",
  chef: "Chef",
  chef_faqs: "Chef FAQS",
  chef_services: "Chef Services",
  chefs: "Chefs",
  chefs_active_NUM: "Chefs Active (%@)",
  choose_file: "Choose file",
  choose_image: "Choose image",
  choose_profile_type:
    "Is essential that you do not make a mistake when choosing your profile type,",
  click_date: "Click on a date to see your application details",
  closed: "Closed",
  company: "Company",
  contact: "Contact",
  contact_email: "Contact email",
  cookie_policy: "Cookie Policy",
  copy_link: "Copy link",
  copyright: "2018. CHEFS LINK. ALL RIGHTS RESERVED.",
  cover_picture: "Cover Picture",
  covers: "Covers",
  contact_the_chef: "Contact the chef",
  continue: "Continue",
  continue_later: "Continue later",
  create_account: "Create an account",
  create_account_description_1:
    "If you do not yet have a user account created by chefslink.com, use this option to access the registration form.",
  create_account_description_2:
    "Enter your email and we will ask you for the essential information to create an account. You will be able to complete your profile later.",
  create_page: "Create a Page",
  create_page_content:
    "This information will provide us with all the necessary details we need to understand your company’s requirements. By doing so, it allows us to get straight to work.",
  create_page_title: "Help us understand your needs",
  create_profile: "Create a Profile",
  create_profile_content:
    "This information will not only be posted on our website for Restaurants to view, but it will also provide our team with all the necessary information they need to help you find the best possible job.",
  create_profile_title: "Easy step-by-step Ad on our website",
  create_restaurant: "Create Restaurant",
  create_your_profile: "Create your profile and get noticed!",
  create_your_profile_text:
    "Establish yourself in the most exclusive restaurants.",
  cuisine: "Cuisine",
  cuisines: "Cuisines",
  currency: "Currency",
  currently: "Currently",
  currently_studying: "I am currently studying",
  currently_work_here: "I am currently working here",
  curriculum_vitae: "Curriculum vitae",
  cv_provided_by: "CV provided by",

  date: "Date",
  date_of_birth: "Date of birth",
  days_off: "Days off:",
  default_email_message: "Default email message for chefs",
  default_language: "Default language",
  delete: "Delete",
  delete_application: "Do you want to delete this application?",
  delete_conversation:
    "Do you want to delete the conversation? This action is irreversible",
  delete_offer: "Do you want to delete the offer?",
  description: "Description",
  details: "Details",
  dinner: "Dinner",
  direct_message_content_chef:
    "Our Message system makes it easy to chat or share files directly with Premium Restaurants on our website in case you need more information about Job Offers they posted.",
  direct_message_content_restaurant:
    "As a Premium client, you can chat directly with our Chefs in case you need more information, schedule a trial shift or simply see if the Chef might be interested in joining you at your company.",
  direct_message_subtitle_chef: "Need more information",
  direct_message_subtitle_restaurant: "For more information",
  direct_message_title: "Direct Message",
  double_check: "Double check what's to come!",
  download_pdf: "Download PDF",
  drop_file_here: "Drop your file here or ",
  drop_us_a_line: "Drop us a line",

  edit: "Edit",
  edit_about: "Edit About",
  edit_address: "Edit Address",
  edit_chef: "Edit Chef",
  edit_cover: "Edit Cover",
  edit_education: "Edit Education",
  edit_email: "Edit Email",
  edit_language: "Edit Language",
  edit_more_information: "Edit More Information",
  edit_offer: "Edit Offer",
  edit_opening_hours: "Edit Opening Hours",
  edit_page: "Edit Page",
  edit_phone_number: "Edit Phone Number",
  edit_settings: "Edit Settings",
  edit_social_network: "Edit Social Network",
  edit_work_experience: "Edit Work Experience",
  education: "Education",
  education_training: "Education & training",
  email: "Email",
  email_exists:
    'This email is already in use, please click on "Forgot your password" to reset your password. You will recieve an email to reset your password. Or you can email us at info@chefslink.com',
  email_placeholder: "Introduce your email",
  email_reset_password: "We've sent you an email to reset the password",
  enquiry_subject: "Enquiry subject",
  error: "Sorry, there was an error",
  error_request:
    "There was an error processing your request. Please do try again.",

  faq: "FAQ",
  faqs: "FAQS",
  faq_chefs: "FAQ Chefs",
  faq_restaurants: "FAQ Restaurants",
  file_attached: "File attached",
  filter_by: "Filter by",
  file_selected: "You have selected",
  find_chefs: "Find Chefs",
  find_chefs_now: "Find chefs now:",
  find_jobs: "Find Jobs",
  find_jobs_now: "Find jobs now:",
  find_restaurants: "Find Restaurants",
  find_restaurants_now: "Find restaurants now:",
  food_standards: "Food standards",
  forgot_password: "Do you forgot your password?",
  from: "From",
  future_opportunities: "Future Opportunities",

  get_in_touch: "Get in touch",
  go: "Go",
  go_back: "Go back",
  guru_template: "Guru template",

  head_chef: "Head Chef",
  header: "Header",
  headline: "Headline",
  health_safety: "Health and safety",
  help_job_description: "Need help writing a job description?",
  help_job_description_text:
    "Click on the items that describe your recruitment goals best and edit the details afterwards.",
  help_recruit:
    "Help us recruit for this position (3% recruitment fee will apply)",
  hide: "Hide",
  hide_menu: "Hide menu",
  hide_salary: "Hide salary",
  holidays: "Holidays:",
  home: "Home",
  how_do_we_work: "How do we work",
  how_do_we_work_chefs: "How do we work for chefs?",
  how_do_we_work_chefs_text:
    "Chefs Link is a free service for all our Chefs looking for jobs. No risk and no payment is needed whatsoever. We have access to an extensive restaurant network with the best possible career opportunities available in the restaurant scene. Opportunities that promises to boost your career as a Chef, and will allow you to work alongside and be mentored by some of the best Chefs in the world. We are dedicated to helping you establish and manage your career as a Chef.",
  how_do_we_work_restaurants: "How do we work for restaurants?",
  how_do_we_work_restaurants_text:
    "We dedicate our time to finding the most talented Chefs for our Restaurants through our networks, marketing and good relationships with some of the best culinary schools and universities in the world. It enables us to find the talent that will meet your specific needs. Our company is designed to eliminate the hassle and frustration usually associated with the recruitment process by making it as easy as possible for you and your business.",

  import_template_NAME: 'Would you like to import the template "%@"?',
  in: "In",
  in_review: "In Review",
  inactive: "Inactive",
  inactive_NUM: "Inactive (%@)",
  info: "Info:",
  invalid_email: "Invalid email",

  job_description: "Job description",
  job_offers: "Job Offers",
  job_offers_NUM: "Job Offers (%@)",
  job_offers_you_saved: "These are the job offers you have saved!",
  join_our_network_today: "Join our network today!",
  join_the_community: "Or you can join the community and ",

  language: "Language",
  languages: "Languages",
  last_job_offers_NUM: "Last job offers (%@)",
  last_login: "Last login",
  latest_job_offer_alerts_content:
    "You will receive a weekly email alert with all the latest job offers that were automatically linked to your profile according to the information you have provided.",
  latest_job_offer_alerts_title: "Latest Job Offer Alerts",
  latest_job_offer_alerts_subtitle: "Offers that links to your Profile",
  launch_guru_template: "Launch the Guru template",
  less: "- Less",
  level: "Level",
  link: "Link",
  link_now: "Link now",
  link_offer: "Link for the offer",
  link_tech_content_chef:
    "Our website’s smart-profiling will automatically link you with the Restaurants that matter to you. It will only show you the offers that match your profile, so you don’t have to waste time or spend hours searching through job offers that are irrelevant to you.",
  link_tech_content_restaurant:
    "Our website’s smart-profiling will automatically link you with the Chefs that matter most to your company, so you don’t have to waste time or spend hours searching through hundreds of CVs that are irrelevant to you.",
  link_tech_subtitle_chef: "Linking you with the right people",
  link_tech_subtitle_restaurant: "Linking you with the right Chefs",
  link_tech_title: "Link Technology",
  link_to_our_page:
    "Link to our page to see our latest job offers at the top of your News Feed and receive a notification when we post a new job offer.",
  link_with_us: "Link with us",
  links: "Links",
  links_accepted_NUM: "You have %@ accepted link%@!",
  links_for_you: "Links for you!",
  links_pending_NUM: "You have %@ pending link%@!",
  links_rejected_NUM: "You have %@ rejected link%@!",
  load_more_items: "Load more items",
  loading: "Loading",
  location: "Location",
  log_in: "Log In",
  login: "Login",
  logout: "Logout",
  lunch: "Lunch",

  manage: "Manage",
  manage_pages: "Manage Pages",
  manage_your_pages: "Manage your pages",
  management: "Management",
  me: "Me",
  media: "Media",
  member_login: "LOGIN",
  membership_requested: "Membership requested",
  menu: "Menu",
  mess: "Mess.",
  message: "Message",
  message_from_restaurant: "Message from the restaurant",
  message_sent: "Message sent",
  message_was_sent: "The message was sent",
  messages: "Messages",
  messages_from: "Messages from",
  messages_overview: "Your messages overview",
  miniature: "Miniature",
  month: "Month",
  more: "+ More",
  moremore: "More",
  more_info: "More information",
  my_jobs_NUM: "My jobs (%@)",
  my_profile: "My profile",

  name: "Name",
  name_placeholder: "Introduce your name",
  nationalities: "Nationalities",
  negotiable_salary: " - Negotiable salary",
  new_password: "New Password",
  new_trial_date: "New date:",
  no_accepted_applications: "There are no accepted applications",
  no_accepted_links: "There are no accepted links",
  no_active_offers: "There are no active offers",
  no_emails_allowed: " -NO EMAILS ALLOWED- ",
  no_in_review_applications: "There are no in-review applications",
  no_information: "No information",
  no_job_offers: "There are no job offers",
  no_message: "No message",
  no_messages: "There are no messages",
  no_notifications: "There are no notifications",
  no_pending_applications: "There are no pending applications",
  no_pending_links: "There are no pending links",
  no_rejected_applications: "There are no rejected applications",
  no_rejected_links: "There are no rejected links",
  no_results: "There are no results for your search",
  no_saved_offers: "There are no saved offers",
  no_sent_applications: "There are no sent applications",
  no_stages_available: "There are no stages available",
  no_time: "No time has been specified",
  no_trials: "There are no applications",
  no_urls_allowed: " -NO URLS ALLOWED- ",
  not_available: "not available",
  not_logged_in:
    "You need to be logged to continue. Not a member yet? <strong>Create a free account</strong> and have access to unlimited offers, apply and contact directly with restaurants around the world.",
  notifications: "Notifications",

  offer_not_available: "This offer is not available.",
  offer_saved: "The offer was saved",
  offer_sent: "Offer sent",
  offer_sent_to_client: "The offer was sent to the client",
  offers: "Offers",
  ok: "Ok",
  only_cv_subject: "Application for offer",
  only_cv_subject_plural: "Application for offers",
  only_cv_text: "published by restaurant",
  only_cv_text_intro:
    "I would like to send my CV to apply the following offer:",
  only_cv_text_intro_plural:
    "I would like to send my CV to apply the following offers:",
  opening_hours: "Opening Hours",
  other_duties: "Other duties",

  password: "Password",
  password_changed: "Your password has been changed",
  password_placeholder: "Introduce your password",
  pause: "Pause",
  paused: "Paused",
  pending: "Pending",
  pending_applicants_NUM: "%@ pending applicants",
  person: "Person",
  phone: "Phone",
  phone_code_number: "Phone Code Number",
  phone_number: "Phone number",
  phone_placeholder: "Introduce your phone",
  plan_that_works: "A plan that works",
  plan_that_works_business: "A plan that works for your business",
  position: "Position",
  post_job_offers: "Post Job Offers",
  post_jobs: "Post Jobs",
  post_jobs_content:
    "Create as many Job offers on our website as you like and even store the ads in your profile for future recruitment use. With the information provided in the ads, our recruitment team will start looking for Chefs and send you possible candidates.",
  post_jobs_subtitle: "Creating quick and easy Ads on our website",
  premium: "Premium",
  preview: "Preview",
  preview_as_restaurant: "Preview as restaurant",
  previous_experience: "Previous experience",
  privacy_policy: "Privacy Policy",
  profile: "Profile",
  profile_type_placeholder: "Are you a Chef or a Restaurant?",
  profile_types: "Chef or Restaurant",
  propose_new_date: "Propose a new date",
  proposed_offer: "Proposed offer",
  published_on: "Published on",

  questions: "Do you have any questions?",
  questions_text_1:
    "We have prepared a list with the most frequently asked questions (FAQ) to help you with any doubts you might have. In the case you don’t find the answer",
  questions_text_2:
    "you need do not hesitate to contact us. We will be please to help you.",

  rating: "Rating",
  rating_type: "Rating Type",
  reached_limit: "You have reached the limit of 10 chefs",
  read_less: "- Read less",
  read_more: "+ Read more",
  register_as: "Register as",
  reject: "Reject",
  rejected: "Rejected",
  rejected_trial_dates:
    "Unfortunately the restaurant has rejected you proposed dates. However, they suggested another date to do the (%@). Please acept or suggest another available date.",
  related_job_offers_NUM: "Related job offers (%@)",
  remember_me: "Remember me",
  remove_file: "Would you like to remove the file?",
  repeat_new_password: "Repeat new password",
  repeat_password: "Repeat password",
  repeat_password_placeholder: "Repeat your password",
  report: "Report",
  report_page: "Report this page to Chefs Link",
  request: "Request",
  request_callback: "Request a call back",
  request_sent: "The request was sent",
  reset_password: "Reset password",
  restaurant: "Restaurant",
  restaurant_faqs: "Restaurant FAQS",
  restaurant_manager_email:
    "Email to receive applications and messages for this restaurant (optional)",
  restaurant_message: "Restaurant message",
  restaurant_profile: "Restaurant Profile",
  restaurant_profiles: "Restaurant Profiles",
  restaurant_proposed_offer: "The restaurant has proposed an offer",
  restaurant_services: "Restaurant Services",
  restaurants: "Restaurants",
  restaurants_list_NUM: "Restaurants list (%@)",

  salary: "Salary",
  salary_from: "Salary From",
  salary_to: "Salary To",
  save: "Save",
  save_add_more: "Save and add more",
  save_continue: "Save and continue",
  saved: "Saved",
  search_chefs_content:
    "All the Chefs in our network have a summary of their curriculums with only the most relevant information about their careers as a Chef, posted on our website. Simply selecting the Chefs profiles that interest your requirements and send them a Job Offer.",
  search_chefs_subtitle: "Need Chefs",
  search_chefs_title: "Search for Chefs",
  search_results: "Search results",
  search_thousands_offer: "Search thousands of job offer for chefs",
  sector: "Sector",
  select_audience_content:
    "When you sign up with us, we will give you the option to select your audience’s Nationality & Language. It will allow only for certain Chefs to see and apply to your Job Offers and will also only show those profiles in the Find Chefs section.",
  select_audience_subtitle: "Who do you want to hire",
  select_audience_title: "Select your audience",
  select_available_trial_dates: "Select up to three dates you are available",
  select_dates: "Select the initial and finish dates",
  select_file: "Select a file from your media library",
  select_new_trial_date: "Please select a new available date",
  select_offer: "Select an offer",
  select_status: "Select a status",
  select_type: "Select type",
  selected_chefs_NUM: "(%@) chefs selected for this offer",
  selected_chefs_NUM_mobile: "Selected chefs (%@)",
  selected_offers_NUM: "You have selected (%@) job offers",
  selected_offers_NUM_mobile: "Selected offers (%@)",
  selected_one_file: "You have selected one file",
  send: "Send",
  send_cv: "Send CV",
  send_cv_only: "Send CV only",
  send_curriculum_vitae: "Send curriculum vitae",
  send_job_offer: "Send job offer",
  send_message: "Send a message",
  send_message_to_restaurant: "Send the restaurant a message",
  send_offer: "Send offer",
  sent: "Sent",
  sent_it: "Sent it",
  sent_you_message: " sent you a message",
  services: "Services",
  services_chefs: "Services for Chefs",
  services_restaurants: "Services for Restaurants",
  settings: "Settings",
  share: "Share",
  share_on: "Share on",
  share_profile_content:
    "You can share or download your profile by simply clicking on the share button. It will give you a few options to download in a Cv format or copy the URL and send as an attachment on any social network, message inbox or email.",
  share_profile_subtitle: "Always have your profile “CV” with you.",
  share_profile_title: "Share your Profile",
  show_menu: "Show menu",
  showcase: "Showcase",
  sign_up: "Sign up",
  signup_terms: "Creating an account means you're okay with our ",
  smart_banner_apple: " - On the App Store",
  smart_banner_free: "FREE",
  smart_banner_google: " - In Google Play",
  smart_banner_view: "VIEW",
  social_network: "Social network",
  social_networks: "Social Networks",
  square: "Square",
  staff_development: "Staff development",
  stage: "Stage",
  stage_info: "Stage info",
  start_adding_chefs: "Start adding Chefs",
  start_adding_covers: "Start adding Covers",
  start_date: "Start date:",
  start_editing_opening_hours: "Start editing your Opening Hours",
  status: "Status",
  stay_up_to_date: "Stay up to date!",
  stay_up_to_date_profile: "Stay up to date with your profile!",
  success: "Success",
  support: "Support",
  surname: "Surname",
  switch: "Switch",
  switch_active_page: "Switch active page",

  team: "Team",
  template: "Template",
  term: "Term:",
  text: "Text",
  the_chef: "The Chef",
  them: "Them",
  title: "Title",
  to: "To",
  total_applications_NUM: "Total applications (%@)",
  trial_date: "(%@) date:",
  type: "Type",
  type_or_options: "Start typing or select one of our options",

  unblock: "Unblock",
  upgrade_premium: "Upgrade to Premium",
  upload: "Upload",
  upload_cv: "Upload your CV",
  url: "URL",

  view_calendar: "View calendar",
  view_links: "View Links",
  view_media: "View media",

  w_h: "W/H:",
  w_hours: "W/Hours:",
  waiting_chef_response: "Waiting for chef response",
  waiting_response: "Waiting for response",
  waiting_restaurant_confirmation:
    "We're waiting for the restaurant confirmation",
  waiting_restaurant_response: "Waiting for restaurant response",
  weekly_days_off: "Weekly days off",
  weekly_hours: "Weekly hours",
  when_available_to_start:
    "In caqse you are accepted, when are you available to start?",
  work_experience: "Work experience",
  work_visas: "Work Visas",
  write_new_message_here: "Write your new message here",

  year: "Year",
  your_conversation: "Your conversation",
  your_enquiry: "Your enquiry",
  your_message: "Your message",
  your_job_offers_NUM: "Your Job Offers (%@)",

  // Form error messages: (chef profile)
  error_email_required: "Email is required",
  error_email_valid: "Email must be valid",
  error_password_required: "Password is required",
  error_password_length: "Password must be at least 6 characters long",
  error_terms_required: "You must accept our terms to continue",
  error_type_profile: "You must select a profile type",
  error_name_letters: "Name can only contain letters",
  error_name_length: "Name must be at least 2 characters long",
  error_name_required: "Name is required",
  error_phone_numbers: "Phone can only contain numbers",
  error_phone_length: "Phone number must be at least 4 characters long",
  error_phone_required: "Phone is required",
  error_password_match: "Passwords doesn't match",
  error_password_confirm: "Confirm your password",
  error_login_combination: "The username/password combination doesn't exist",
  error_email_unique: "The email is already in use",
  error_required_fields: "You must fill in the required fields",

  // Forms placeholders: (chef profile)
  placeholder_position: "Select your position",
  placeholder_company_name: "Add center's name",
  placeholder_company_url: "Add center's website",
  placeholder_location: "Add a location",
  placeholder_select_type: "Select type",
  placeholder_select_month: "Select month",
  placeholder_select_year: "Select year",
  placeholder_education_title: "Add education title",
  placeholder_center_name: "Add center's name",
  placeholder_center_url: "Add center's website",
  placeholder_select_language: "Select language",
  placeholder_select_level: "Select level",
  placeholder_select_country: "Select country",

  // Chef profile literals:
  add_description:
    "Add a description in your profile, so that people know you a little more.",
  add_work_experience: "Add your work experience.",
  add_education: "Add your education.",
  add_languages: "Add your languages",
  add_referrals: "Add more information that may be of interest.",
  no_chef_info: "The chef has not added information yet.",
  start_your_profile: "Hi! Start to complete your profile.",
  start: "Start",
  profile_status: "Profile status",
  add_work_experience_extended:
    "Start adding your work experience.<br>You can add more, edit or delete from your profile page.",
  add_education_extended:
    "Start adding your education.<br>You can add more, edit or delete from your profile page.",
  add_languages_extended:
    "Start adding your languages.<br>You can add more, edit or delete from your profile page.",
  add_referrals_extended:
    "Add more information that may be of interest.<br>You can add more, edit or delete from your profile page.",
};
