import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import moment from "moment";

import { translate } from "../../Translations/translate";

import {
  sectors,
  positions,
  cuisines,
  ratingTypes,
  ratings,
  months,
} from "../../Lists/lists";
import ChefWorkExperienceSchema from "../../FormValidations/ChefWorkExperienceSchema";
import { chefEditWorkExperience } from "../../Services/Chef/chef";

import FormField from "./FormField";
import FormSelect from "./FormSelect";
import FormSelectLocation from "./FormSelectLocation";
import FormCheckbox from "./FormCheckbox";
import Button from "./Button";
import FormTextarea from "./FormTextarea";

const ChefWorkExperienceForm = ({ props, textTranslation, textLang }) => {
  const { lang } = props.match.params;
  const { showOverlay, data, setUser } = props;
  const { value, profileNav } = data;

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingAddMore, setLoadingAddMore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [reset, setReset] = useState(false);
  const [formError, setFormError] = useState(null);
  const [changedText, setChangedText] = useState(null);

  const currentYear = moment().year();
  const years = [];
  for (var y = 0; y < 60; y++) {
    years.push({ value: currentYear - y, label: currentYear - y });
  }

  const handleSave = (values, currentLang, closeModal, addMore = false) => {
    if (!addMore) {
      setLoadingSave(true);
    } else {
      setLoadingAddMore(true);
    }
    const monthValues = months[lang].map((m) => m.value).join(",");
    const yearValues = years.map((y) => y.value).join(",");

    values.months = monthValues;
    values.years = yearValues;
    const formData = {
      id: values.id,
      name: "work",
      order: 0,
      language: currentLang,
      value: JSON.stringify(values),
    };
    chefEditWorkExperience(formData)
      .then((res) => {
        setLoadingSave(false);
        if (!res.data.success) {
          setFormError(res.data.message);
          return;
        }
        const response = res.data.data;
        setUser(response.user);
        if (profileNav && !addMore) {
          showOverlay("chefeducation", { id: 0, value: {}, profileNav: true });
        } else if (addMore) {
          setLoadingAddMore(false);
          setReset(true);
        } else if (closeModal) {
          showOverlay(null);
        }
      })
      .catch((e) => {
        setLoadingSave(false);
        setFormError(e.message);
      });
  };

  const handleDelete = () => {
    setLoadingDelete(true);
    chefEditWorkExperience({ id: data.id, action: "delete", lang })
      .then((res) => {
        setLoadingDelete(false);
        if (!res.data.success) {
          setFormError(res.data.message);
          return;
        }
        const response = res.data.data;
        setUser(response.user);
        showOverlay(null);
      })
      .catch((e) => {
        setFormError(e.message);
      });
  };

  return (
    <div className="component_ChefWorkExperienceForm">
      <Formik
        initialValues={{
          id: data.id ? parseInt(data.id) : 0,
          position: value.position ? value.position : "",
          company: value.company ? value.company : "",
          url: value.url ? value.url : "",
          rating: value.rating ? value.rating : "",
          ratingType:
            value.ratingType || value.ratingtype
              ? value.ratingType || value.ratingtype
              : "",
          location: value.location ? value.location : "",
          cuisine: value.cuisine ? value.cuisine : "",
          sector: value.sector ? value.sector : "",
          fromMonth: value.fromMonth ? value.fromMonth : "",
          fromYear: value.fromYear ? value.fromYear : "",
          toMonth: value.toMonth ? value.toMonth : "",
          toYear: value.toYear ? value.toYear : "",
          currently: value.currently ? value.currently : false,
          description: value.description ? value.description : "",
          months: months[lang],
          currentYear: currentYear,
          years: years,
        }}
        validationSchema={ChefWorkExperienceSchema(lang)}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => handleSave(values, textLang, true)}
        enableReinitialize
      >
        {({
          values,
          errors,
          handleChange,
          setFieldValue,
          resetForm,
          validateForm,
        }) => {
          useEffect(() => {
            if (textTranslation) {
              if (changedText) {
                handleSave(values, changedText, false);
                setChangedText(null);
                setFieldValue("description", textTranslation);
              } else {
                setFieldValue("description", textTranslation);
              }
            }
          }, [textTranslation]);

          useEffect(() => {
            if (reset) {
              resetForm();
              setReset(false);
            }
          }, [reset]);

          return (
            <Form>
              <div className="fullWidth">
                <div className="halfWidth marginRight">
                  <FormSelect
                    id="position"
                    name="position"
                    label={translate(lang, "position")}
                    options={positions[lang]}
                    errors={errors}
                    formError={formError}
                    placeholder={translate(lang, "placeholder_position")}
                    showErrorMessage={false}
                  />
                </div>
                <div className="halfWidth marginLeft">
                  <FormField
                    id="company"
                    name="company"
                    label={translate(lang, "company")}
                    placeholder={translate(lang, "placeholder_company_name")}
                    value={values.company}
                    onChange={handleChange}
                    errors={errors}
                    formError={formError}
                    showErrorMessage={false}
                  />
                </div>
              </div>
              <div className="fullWidth">
                <div className="halfWidth marginRight">
                  <FormField
                    id="url"
                    name="url"
                    label={translate(lang, "url")}
                    placeholder={translate(lang, "placeholder_company_url")}
                    value={values.url}
                    onChange={handleChange}
                    errors={errors}
                    formError={formError}
                    showErrorMessage={false}
                  />
                </div>
                <div className="halfWidth marginLeft">
                  <FormSelectLocation
                    id="location"
                    label={translate(lang, "location")}
                    name="location"
                    error={errors}
                    value={values.location}
                    onChange={(v) => setFieldValue("location", v)}
                    labelClassName="inputLabel"
                    placeholder={translate(lang, "placeholder_location")}
                    showErrorMessage={false}
                    reset={reset}
                  />
                </div>
              </div>
              <div className="fullWidth">
                <div className="halfWidth marginRight">
                  <div className="halfWidth marginRight">
                    <FormSelect
                      id="cuisine"
                      name="cuisine"
                      label={translate(lang, "cuisine")}
                      options={cuisines[lang]}
                      errors={errors}
                      formError={formError}
                      placeholder={translate(lang, "placeholder_select_type")}
                      showErrorMessage={false}
                    />
                  </div>
                  <div className="halfWidth marginLeft">
                    <FormSelect
                      id="sector"
                      name="sector"
                      label={translate(lang, "sector")}
                      options={sectors[lang]}
                      errors={errors}
                      formError={formError}
                      placeholder={translate(lang, "placeholder_select_type")}
                      showErrorMessage={false}
                    />
                  </div>
                </div>
                <div className="halfWidth marginLeft">
                  <div className="halfWidth marginRight">
                    <FormSelect
                      id="rating"
                      name="rating"
                      label={translate(lang, "rating")}
                      options={ratings[lang]}
                      errors={errors}
                      formError={formError}
                      placeholder={translate(lang, "placeholder_select_type")}
                      showErrorMessage={false}
                      disableSearch
                    />
                  </div>
                  <div className="halfWidth marginLeft">
                    <FormSelect
                      id="ratingType"
                      name="ratingType"
                      label={translate(lang, "rating_type")}
                      options={ratingTypes[lang]}
                      errors={errors}
                      formError={formError}
                      placeholder={translate(lang, "placeholder_select_type")}
                      showErrorMessage={false}
                    />
                  </div>
                </div>
              </div>
              <div className="fullWidth">
                <div className="halfWidth marginRight">
                  <div className="halfWidth marginRight">
                    <FormSelect
                      id="fromMonth"
                      name="fromMonth"
                      label={translate(lang, "from")}
                      options={months[lang]}
                      errors={errors}
                      formError={formError}
                      placeholder={translate(lang, "placeholder_select_month")}
                      showErrorMessage={false}
                    />
                  </div>
                  <div className="halfWidth marginLeft">
                    <FormSelect
                      id="fromYear"
                      name="fromYear"
                      options={years}
                      errors={errors}
                      formError={formError}
                      placeholder={translate(lang, "placeholder_select_year")}
                      showErrorMessage={false}
                    />
                  </div>
                </div>
                <div className="halfWidth marginLeft desktop">
                  <FormCheckbox
                    id="currently"
                    name="currently"
                    label={`${translate(lang, "currently_work_here")}`}
                    checked={values.currently}
                    onChange={handleChange}
                    errors={errors}
                    showErrorMessage={false}
                  />
                </div>
              </div>
              <div className="fullWidth">
                <div
                  className={`halfWidth marginRight ${
                    values.currently ? "hidden" : ""
                  }`}
                >
                  <div className="halfWidth marginRight">
                    <FormSelect
                      id="toMonth"
                      name="toMonth"
                      label={translate(lang, "to")}
                      options={months[lang]}
                      errors={errors}
                      formError={formError}
                      placeholder={translate(lang, "placeholder_select_month")}
                      showErrorMessage={false}
                    />
                  </div>
                  <div className="halfWidth marginRight marginLeft">
                    <FormSelect
                      id="toYear"
                      name="toYear"
                      options={years}
                      errors={errors}
                      formError={formError}
                      placeholder={translate(lang, "placeholder_select_year")}
                      showErrorMessage={false}
                    />
                  </div>
                </div>
              </div>
              <div className="fullWidth mobile">
                <FormCheckbox
                  id="currently"
                  name="currently"
                  label={`${translate(lang, "currently_work_here")}`}
                  checked={values.currently}
                  onChange={handleChange}
                  errors={errors}
                  showErrorMessage={false}
                />
              </div>
              <div className="fullWidth textarea">
                <FormTextarea
                  id="description"
                  name="description"
                  label={translate(lang, "description")}
                  placeholder={null}
                  errors={errors}
                  onChange={(v) => {
                    setChangedText(textLang);
                    setFieldValue("description", v);
                  }}
                  lang={lang}
                  textTranslation={textTranslation}
                  addMediaCallback={false}
                  reset={reset}
                />
              </div>
              <div
                className={`buttonRow ${
                  formError || (errors && Object.keys(errors).length > 0)
                    ? "hasError"
                    : ""
                }`}
              >
                {formError && (
                  <div
                    className="errorMessage"
                    dangerouslySetInnerHTML={{ __html: formError }}
                  />
                )}
                {errors && Object.keys(errors).length > 0 && (
                  <div className="errorMessage">
                    {translate(lang, "error_required_fields")}
                  </div>
                )}
                <div className="buttons">
                  {parseInt(data.id) !== 0 && (
                    <div className="nextTo">
                      <Button
                        label={translate(lang, "delete").toUpperCase()}
                        className="red"
                        loading={loadingDelete}
                        onClick={() => handleDelete()}
                      />
                    </div>
                  )}
                  {parseInt(data.id) === 0 && (
                    <div className="nextTo">
                      <Button
                        label={translate(lang, "save_add_more").toUpperCase()}
                        loading={loadingAddMore}
                        onClick={() =>
                          validateForm().then((errors) => {
                            if (Object.keys(errors).length === 0) {
                              handleSave(values, textLang, false, true);
                            }
                          })
                        }
                      />
                    </div>
                  )}
                  <Button
                    label={
                      profileNav
                        ? translate(lang, "save_continue").toUpperCase()
                        : translate(lang, "save").toUpperCase()
                    }
                    submit
                    loading={loadingSave}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChefWorkExperienceForm;
