import { lists_en } from "./lists/lists_en";
import { lists_es } from "./lists/lists_es";
import { lists_fr } from "./lists/lists_fr";
import { lists_pt } from "./lists/lists_pt";

export const disponibility = {
  en: lists_en.disponibility,
  es: lists_es.disponibility,
  fr: lists_fr.disponibility,
  pt: lists_pt.disponibility,
};

export const currencies = {
  en: lists_en.currencies,
  es: lists_es.currencies,
  fr: lists_fr.currencies,
  pt: lists_pt.currencies,
};

export const positions = {
  en: lists_en.positions,
  es: lists_es.positions,
  fr: lists_fr.positions,
  pt: lists_pt.positions,
};

export const ratings = {
  en: lists_en.ratings,
  es: lists_es.ratings,
  fr: lists_fr.ratings,
  pt: lists_pt.ratings,
};

export const ratingTypes = {
  en: lists_en.ratingTypes,
  es: lists_es.ratingTypes,
  fr: lists_fr.ratingTypes,
  pt: lists_pt.ratingTypes,
};

export const sectors = {
  en: lists_en.sectors,
  es: lists_es.sectors,
  fr: lists_fr.sectors,
  pt: lists_pt.sectors,
};

export const levels = {
  en: lists_en.levels,
  es: lists_es.levels,
  fr: lists_fr.levels,
  pt: lists_pt.levels,
};

export const languages = {
  en: lists_en.languages,
  es: lists_es.languages,
  fr: lists_fr.languages,
  pt: lists_pt.languages,
};

export const cuisines = {
  en: lists_en.cuisines,
  es: lists_es.cuisines,
  fr: lists_fr.cuisines,
  pt: lists_pt.cuisines,
};

export const socialNetworks = {
  en: lists_en.socialNetworks,
  es: lists_es.socialNetworks,
  fr: lists_fr.socialNetworks,
  pt: lists_pt.socialNetworks,
};

export const salaryTypes = {
  en: lists_en.salaryTypes,
  es: lists_es.salaryTypes,
  fr: lists_fr.salaryTypes,
  pt: lists_pt.salaryTypes,
};

export const terms = {
  en: lists_en.terms,
  es: lists_es.terms,
  fr: lists_fr.terms,
  pt: lists_pt.terms,
};

export const shifts = {
  en: lists_en.shifts,
  es: lists_es.shifts,
  fr: lists_fr.shifts,
  pt: lists_pt.shifts,
};

export const days = {
  en: lists_en.days,
  es: lists_es.days,
  fr: lists_fr.days,
  pt: lists_pt.days,
};

export const hours = {
  en: lists_en.hours,
  es: lists_es.hours,
  fr: lists_fr.hours,
  pt: lists_pt.hours,
};

export const countryCodes = {
  en: lists_en.countryCodes,
  es: lists_es.countryCodes,
  fr: lists_fr.countryCodes,
  pt: lists_pt.countryCodes,
};

export const countries = {
  en: lists_en.countries,
  es: lists_es.countries,
  fr: lists_fr.countries,
  pt: lists_pt.countries,
};

export const applicationTypes = {
  en: lists_en.applicationTypes,
  es: lists_es.applicationTypes,
  fr: lists_fr.applicationTypes,
  pt: lists_pt.applicationTypes,
};

export const months = {
  en: lists_en.months,
  es: lists_es.months,
  fr: lists_fr.months,
  pt: lists_pt.months,
};
