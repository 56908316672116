import ChefEducationForm from "../_NEW/ChefEducationForm";
import React, { Component } from "react";
import Btn from "../../Components/Btn/Btn";
import Checkbox from "../../Components/Input/Checkbox";
import Input from "../../Components/Input/Input";
import Overlay from "./Overlay";
import Select from "react-select";
import Textarea from "../../Components/Input/Textarea";
import moment from "moment";
import { connect } from "react-redux";
import { ActionCreators } from "../../Actions";
import { chefEditEducation } from "../../Services/Chef/chef";
import { validate } from "../../Components/Input/validate";
import { translate } from "../../Translations/translate";
import { withRouter } from "react-router";
import { refreshUser } from "../../Services/Access/access";
import { months } from "../../Lists/lists";
import Cookies from "js-cookie";
var find = require("lodash/find");

class ChefEducationOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      loading: Cookies.get("token") !== undefined,
      changedContents: false,
      lang: props.match.params.lang,
    };

    this.educationTextarea = React.createRef();
  }

  parseDataToState(value = this.props.data.value, callback = () => {}) {
    if (value.value) {
      let id = value.id ? value.id : value.value.id;
      if (typeof id === "undefined") id = 0;
      value = value.value;
      value.id = id;
    }

    let currentYear = moment().year();
    var years = [];
    for (var y = 0; y < 60; y++) {
      years.push({ value: currentYear - y, label: currentYear - y });
    }

    this.setState(
      {
        id: parseInt(value.id),
        title: value.title ? value.title : "",
        center: value.center ? value.center : "",
        url: value.url ? value.url : "",
        fromMonth: value.fromMonth ? value.fromMonth : null,
        fromYear: value.fromYear ? value.fromYear : null,
        toMonth: value.toMonth ? value.toMonth : null,
        toYear: value.toYear ? value.toYear : null,
        currently: value.currently ? value.currently : false,
        description: value.description ? value.description : "",
        months: months[this.state.lang],
        currentYear: currentYear,
        years: years,
      },
      callback
    );
  }

  componentDidMount() {
    this.parseDataToState(this.props.data, () => {
      this.shouldPerformRefreshUser(this.setState({ loading: false }));
    });
  }

  shouldPerformRefreshUser(callback = () => {}) {
    const { id } = this.state;
    if (typeof id !== "undefined" && id > 0) {
      this.performRefreshUser();
    }
    return callback;
  }

  languageCallback(lang) {
    const { changedContents } = this.state;

    // if (!changedContents) {
    this.setState({ lang }, () => this.shouldPerformRefreshUser());
    return true;
    // } else {
    //     const hasErrors = this.validateForm(false)
    //     this.save(false, () => {
    //         this.setState({ lang }, () => this.shouldPerformRefreshUser())
    //     })
    //     return hasErrors
    // }
  }

  performRefreshUser() {
    const { lang } = this.state;
    const token = Cookies.get("token");
    refreshUser({ token: token, lang }).then((res) => {
      const { user } = res.data.data;

      let description = "";

      if (typeof this.state.id !== "undefined" && this.state.id > 0) {
        const education = user.profile.education.find((education) =>
          parseInt(education.id) === this.state.id ? education : false
        );
        description = education.value.description;
      }

      this.setState({ loading: false, description: description }, () => {
        // this.educationTextarea.setContent(description)
      });
    });
  }

  validateForm(updateState = true) {
    let items = [
      { field: "title", minLength: 2 },
      { field: "center", minLength: 2 },
      { field: "fromMonth" },
      { field: "fromYear" },
    ];
    let errors = validate(items, this.state);
    if (updateState) this.setState({ errors });
    return errors.length === 0;
  }

  handleChange(name, value) {
    this.setState({ [name]: value, changedContents: true });
  }

  save(closeModelAfterSave = false, callback = () => {}) {
    const { lang } = this.state;

    const isValid = this.validateForm();

    if (isValid) {
      let months = [];
      this.state.months.forEach((n) => {
        months.push(n.value);
      });

      let years = [];
      this.state.years.forEach((n) => {
        years.push(n.value);
      });

      let updateData = {
        id: this.state.id,
        name: "education",
        order: 0,
        language: lang,
        value: JSON.stringify({
          title: this.state.title,
          center: this.state.center,
          url: this.state.url,
          fromMonth: this.state.fromMonth,
          fromYear: this.state.fromYear,
          toMonth: this.state.toMonth,
          toYear: this.state.toYear,
          currently: this.state.currently,
          description: this.state.description,
          lang: lang,
          location: this.state.location,
          months: months.join(","),
          currentYear: this.state.currentYear,
          years: years.join(","),
        }),
      };

      this.setState({ loading: true });

      chefEditEducation(updateData)
        .then((res) => {
          this.setState({ loading: false, changedContents: false });
          if (!res.data.success) {
            this.props.showOverlay("error", { message: res.data.message });
            return;
          } else {
            const { data } = res.data;

            if (typeof updateData.id !== "undefined" && updateData.id > 0) {
              const education = data.user.profile.education.find((education) =>
                parseInt(education.id) === updateData.id ? education : ""
              );
              education.value.id = education.id;
              this.parseDataToState(education.value);
            } else {
              const education =
                data.user.profile.education[
                  data.user.profile.education.length - 1
                ];
              education.value.id = education.id;
              this.parseDataToState(education.value);
            }

            this.props.setUser(data.user);
            if (closeModelAfterSave) {
              this.props.showOverlay(null);
            } else {
              if (callback) callback();
            }
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
          this.props.showOverlay("error", { message: e.message });
        });
    }
  }

  renderWorkTo() {
    const { lang } = this.props.match.params;
    if (this.state.currently) {
      return null;
    } else {
      return (
        <div className="col-mb-12 col-8 group-container">
          <div className="col-group">
            <div className="col-mb-12 col-6">
              <label htmlFor="type">{translate(lang, "to")}</label>
              <Select
                placeholder={translate(lang, "month").toUpperCase()}
                name="toMonth"
                value={this.state.toMonth}
                className={
                  this.state.errors.indexOf("toMonth") !== -1 ? "error" : null
                }
                onChange={(option) =>
                  this.handleChange("toMonth", option ? option.value : -1)
                }
                options={this.state.months}
              />
            </div>
            <div className="col-mb-12 col-6">
              <label htmlFor="type">&nbsp;</label>
              <Select
                placeholder={translate(lang, "year").toUpperCase()}
                name="toYear"
                value={this.state.toYear}
                className={
                  this.state.errors.indexOf("toYear") !== -1 ? "error" : null
                }
                onChange={(option) =>
                  this.handleChange("toYear", option ? option.value : -1)
                }
                options={this.state.years}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  delete() {
    const { lang } = this.props.match.params;
    this.setState({ loadingDelete: true });
    chefEditEducation({ id: this.state.id, action: "delete", lang })
      .then((res) => {
        this.setState({ loadingDelete: false });
        if (!res.data.success) {
          this.props.showOverlay("error", { message: res.data.message });
          return;
        }
        let data = res.data.data;
        this.props.setUser(data.user);
        this.props.showOverlay(null);
      })
      .catch((e) => {
        this.props.showOverlay("error", { message: e.message });
      });
  }

  render() {
    const { lang } = this.props.match.params;
    return (
      <Overlay
        title={translate(lang, "education")}
        className={this.props.className}
        languageTranslator={this.state.id === 0 ? false : true}
        languageCallback={this.languageCallback.bind(this)}
        isNew
        overlayName={this.props.overlayName}
        profileNav={this.props.data.profileNav}
        subtitle={translate(lang, "add_education_extended")}
      >
        <ChefEducationForm
          props={this.props}
          textTranslation={this.state.description}
          textLang={this.state.lang}
        />

        {/* <div className="col-group form">
                    <div className="col-mb-12">
                        <Input
                            label={translate(lang, 'title')}
                            name="title"
                            error={this.state.errors}
                            value={this.state.title}
                            onChange={v => this.handleChange('title', v)}
                            />
                    </div>
                    <div className="col-mb-12 col-6">
                        <Input
                            label={translate(lang, 'center')}
                            name="center"
                            error={this.state.errors}
                            value={this.state.center}
                            onChange={v => this.handleChange('center', v)}
                            />
                    </div>
                    <div className="col-mb-12 col-6">
                        <Input
                            label={translate(lang, 'url')}
                            name="url"
                            error={this.state.errors}
                            value={this.state.url}
                            onChange={v => this.handleChange('url', v)}
                            />
                    </div>
                    <div className="col-mb-12 col-4">
                        <label htmlFor="type">{translate(lang, 'from')}</label>
                        <Select
                            placeholder={translate(lang, 'month').toUpperCase()}
                            name="fromMonth"
                            value={this.state.fromMonth}
                            className={this.state.errors.indexOf('fromMonth') !== -1 ? 'error' : null}
                            onChange={option => this.handleChange('fromMonth', option ? option.value : -1 )}
                            options={this.state.months}
                            />
                    </div>
                    <div className="col-mb-12 col-4">
                        <label htmlFor="type">&nbsp;</label>
                        <Select
                            placeholder={translate(lang, 'year').toUpperCase()}
                            name="fromYear"
                            value={this.state.fromYear}
                            className={this.state.errors.indexOf('fromYear') !== -1 ? 'error' : null}
                            onChange={option => this.handleChange('fromYear', option ? option.value : -1 )}
                            options={this.state.years}
                            />
                    </div>
                    <div className="col-mb-12 col-4">
                        <label htmlFor="type">&nbsp;</label>
                        <Checkbox
                            label={translate(lang, 'currently_studying')}
                            name="currently"
                            error={this.state.errors}
                            checked={this.state.currently}
                            onChange={v => this.handleChange('currently', !this.state.currently)}
                            />
                    </div>

                    {this.renderWorkTo()}

                    <div className="col-mb-12">
                        <Textarea
                            ref={(ref) => this.educationTextarea = ref}
                            lang={lang}
                            label={translate(lang, 'description')}
                            name="about"
                            error={this.state.errors}
                            type="text"
                            value={this.state.description}
                            minLength={4}
                            onChange={v => this.handleChange('description', v)}
                            />
                    </div>
                    <div className="col-mb-12 actions multiple">
                        {this.state.id !== 0 ? <div className="accessory">
                            <Btn text={translate(lang, 'delete').toUpperCase()} loading={this.state.loadingError} className="delete" callback={() => {
                                this.delete()
                            }} />
                        </div> : null}
                        <div className="main">
                            <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                this.props.showOverlay(null)
                            }} />
                            <Btn text={translate(lang, 'save').toUpperCase()} loading={this.state.loading} callback={() => {
                                this.save(true)
                            }} />
                        </div>
                    </div>
                </div> */}
      </Overlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deviceScreen: state.deviceScreen,
  };
};
export default withRouter(
  connect(mapStateToProps, ActionCreators)(ChefEducationOverlay)
);
